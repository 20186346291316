@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button_design {
  @apply bg-blue-500 rounded text-white h-12 w-80;
}

.input_design {
  @apply !outline-none border-b-2 pb-2 placeholder:text-black
}

.react-calendar {
  width: 100% !important;
}

.react-calendar__tile {
  border-radius: 50%;
}


.react-calendar__tile--active {
  background-color: #3b82f6 !important; /* Blue background */
  color: white !important; /* White text */
  border-radius: 50%; /* Rounded corners */
}


.react-calendar__navigation {
  background: #3380ff; /* Blue background for the header */
  color: white; /* White text color */
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.react-calendar__navigation__arrow {
  color: white;
  font-size: x-large;
  background-color: #3380ff;
}

/*disable hover effects*/
.react-calendar__navigation__arrow:hover{
  background-color: #3380ff !important;
}

.react-calendar__navigation__label:hover{
  background-color: #3380ff !important;
}

.react-calendar__navigation__arrow {
  background-color: #3380ff;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.react-calendar__navigation__arrow:disabled {
  background-color: #3380ff;
  color: white;
  opacity: 0.01;
  cursor: not-allowed;
}

/* Optional: if using specific button classes like next2-button */
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev-button {
  /* background-color: #3380ff;  */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.react-calendar__navigation__next2-button:disabled,
.react-calendar__navigation__prev-button:disabled {
  background-color: #3380ff;
  color: white;
  opacity: 0.01;
  cursor: not-allowed;
}


.mNFY9 {
    width: auto !important;
}

.grid-design {
 @apply flex flex-col items-center border border-gray-200 shadow-lg rounded-3xl w-full bg-gray-50 text-xs
}

.dashboard-grid {
  @apply flex flex-col border border-gray-200 shadow-md rounded-xl w-full h-full bg-gray-50 cursor-pointer
}

.dashboard-grid-text {
  @apply lg:text-3xl font-semibold text-[#258BF5] md:text-xl
}

.account-grid {
  @apply flex flex-row items-center border border-gray-200 shadow-md rounded-2xl xl:w-[90%] lg:w-[90%] md:w-[80%] h-[10rem] lg:h-[11rem] md:h-[13rem] bg-gray-50 cursor-pointer
}

.account-grid-title {
  @apply xl:text-2xl lg:text-2xl font-semibold text-blue-500 md:text-xl
}

.account-grid-text {
  @apply text-blue-500 mt-3 xl:text-xl lg:text-xl
}

.sidebar {
  @apply flex flex-row text-white lg:text-2xl mx-5 cursor-pointer md:text-xl

}

.sidebar-icons {
  @apply lg:w-8 lg:h-8 mr-3 md:w-6 md:h-6
}


.callback-slot {
  @apply flex flex-col items-center justify-center rounded-md py-5 max-w-20 h-10
}
