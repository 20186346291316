@import url("/src/assets/fonts/gilroy/stylesheet.css");

.mNFY9 {
    width: auto !important;
}

.dark-blue-bg {
    background-color: #113F93;
}

.apppointment-green-border {
    --tw-border-opacity: 1;
    border-bottom-color: #40ECB8;
}

.annotationLayer {
    width: 100% !important;
    height: 100% !important;
}

.Gilroy {
  font-family: "Gilroy";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text-blue-500 {
    color: #258BF5 !important;
}

.text-blue-600 {
    color: #258BF5 !important;
}

.bg-blue-500 {
    background-color: #1665CE !important;
}

.bg-blue-600 {
    background-color: #1665CE !important;
}

.border-blue-600 {
    border-color: #1665CE !important;
}

.dark-blue-bg {
  background-color: #1665CE !important;
}

.bg-custom-blue {
    background-color: #1665CE !important;
}

.text-sky-500 {
    color: #258BF5 !important;
}

.bg-gray-400 {
    background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

#landing-login-form-wrapper {
    height: 100vh;
    background-size: cover;
    width: 100%;
    background-position: center;
}

#offer-collapsible-wrapper { margin-top: -64px; }
