.slider {
  @apply
  [&::-webkit-slider-runnable-track]:rounded-full
  [&::-webkit-slider-runnable-track]:bg-[#258BF5]
  [&::-webkit-slider-runnable-track]:px-[3%]
  [&::-webkit-slider-thumb]:appearance-none
  [&::-webkit-slider-thumb]:h-5
  [&::-webkit-slider-thumb]:w-5
  [&::-webkit-slider-thumb]:rounded-full
  [&::-webkit-slider-thumb]:bg-slate-50;
}

.scaleTextContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    /* margin-top: -26px; */
    /* border-top-width: 5px; */
    border-color: #258BF5;
    border-style: solid;
    /* z-index: -1; */
}

.scaleVerticalLine {
    display: flex;
    flex: 1;
    border-left-width: 5px;
    height: 20px;
    border-style: solid;
    border-color: #258BF5;
}

.scaleText {
    font-size: 12.5;
    font-weight: bold;
    flex: 1;
    text-align: center;
    margin-left: 10;
    margin-right: 10;
}

.fixed-bg {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}
